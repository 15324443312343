import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"



const rel1 = "\vtext {drift(%)} = 0.8, 1.5";
const rel2 = "D \cdot t = 1524x140 mm";
const rel3 = "M/VD = 4.28";
const rel4 = "\vrho_{l}(%) = 1.45, 3.18";
const rel5 = "\vrho_{t}(%) = 0.65";
const rel6 = "f_{c}' = 38 MPa";
const rel7 = "f_{y} = 436 MPa";
const rel8 = "f_{yh} = 625 MPa";
const rel9 = "P/(f_{c}' A_{g}) = 0.13";

const rel10 = "\vtext {drift(%)} = 2.4, 2.25";
const rel11 = "\vtext {drift(%)} = 3.2, 3.0";

const rel12 = "\vtext {drift(%)} = 1.04 \pm 0.26";
const rel13 = "D \cdot t = 1000x250, 1000x500, 1400x420 mm";
const rel14 = "L = 4000, 5400 mm";

const rel15 = "M/VD = 3.5";
const rel16 = "\vrho_{l}(%) = 0.83÷2.0";
const rel17 = "\vrho_{t}(%) = 0.19÷7.45";
const rel18 = "f_{c}' = 27.5÷39.0 MPa";
const rel19 = "f_{y} = 481, 499 MPa";
const rel20 = "f_{yh} = 473, 495 MPa";
const rel21 = "P/(f_{c}' A_{g}) = 0.065÷0.15";

const rel22 = "\vtext {drift(%)} = 4.14 \pm 1.08";

const rel23 = "\vtext {drift(%)} = 0.5";
const rel24 = "D \cdot t = 1000x400 mm";
const rel25 = "M/VD = 3.65";
const rel26 = "L = 3650mm";
const rel27 = "\vrho_{l}(%) = 1.17";
const rel28 = "\vrho_{t}(%) = 0.63";
const rel29 = "f_{c}' = 31.6, 33.8 MPa";
const rel30 = "f_{y} = 457, 496 MPa";
const rel31 = "f_{yh} = 476, 493 MPa";
const rel32 = "P/(f_{c}' A_{g}) = 0÷0.06";

const rel33 = "\vtext {drift(%)} = 1.5 \pm 1.92";
const rel34 = "\vtext {drift(%)} = 3.61";

const rel35 = "\vtext {drift(%)} = 0.5";
const rel36 = "D \cdot t = 1560x152, 1524x139 mm";
const rel37 = "M/VD = 2.5";
const rel38 = "L = 3581 mm";
const rel39 = "\vrho_{l}(%) = 1.4, 2.3";
const rel40 = "\vrho_{t}(%) = 0.35";
const rel41 = "f_{c}' = 35, 40 MPa";
const rel42 = "f_{y} = 635 MPa";
const rel43 = "P/(f_{c}' A_{g}) = 0.05, 0.15";

const rel44 = "\vtext {drift(%)} = 1.43 \pm 2.14";
const rel45 = "\vtext {drift(%)} = 2.88";
const rel46 = "\vtext {drift(%)} = 2.5";

const rel101 = "\vmu_{d} = 1.0";
const rel102 = "D \cdot t = 1524x140 mm";
const rel103 = "M/VD = 4.28";
const rel104 = "\vrho_{l}(%) = 1.45, 3.18";
const rel105 = "\vrho_{t}(%) = 0.65";
const rel106 = "f_{c}' = 38 MPa";
const rel107 = "f_{y} = 436 MPa";
const rel108 = "f_{yh} = 625 MPa";
const rel109 = "P/(f_{c}' A_{g}) = 0.13";

const rel110 = "\vmu_{d} = 3.0, 1.5";
const rel111 = "\vmu_{d} = 4.0, 2.0";

const rel112 = "\vmu_{d} = 1.0";
const rel113 = "D \cdot t = 1000x250, 1000x500, 1400x420 mm";
const rel114 = "L = 4000, 5400 mm";

const rel115 = "M/VD = 3.5";
const rel116 = "\vrho_{l}(%) = 0.83÷2.0";
const rel117 = "\vrho_{t}(%) = 0.19÷7.45";
const rel118 = "f_{c}' = 27.5÷39.0 MPa";
const rel119 = "f_{y} = 481, 499 MPa";
const rel120 = "f_{yh} = 473, 495 MPa";
const rel121 = "P/(f_{c}' A_{g}) = 0.065÷0.15";

const rel122 = "\vmu_{d} = 4.4 \pm 2.13";

const rel123 = "\vmu_{d} = 1.0";
const rel124 = "D \cdot t = 1000x400 mm";
const rel125 = "M/VD = 3.65";
const rel126 = "L = 3650mm";
const rel127 = "\vrho_{l}(%) = 1.17";
const rel128 = "\vrho_{t}(%) = 0.63";
const rel129 = "f_{c}' = 31.6, 33.8 MPa";
const rel130 = "f_{y} = 457, 496 MPa";
const rel131 = "f_{yh} = 476, 493 MPa";
const rel132 = "P/(f_{c}' A_{g}) = 0÷0.06";

const rel133 = "\vmu_{d} = 3.5";
const rel134 = "\vmu_{d} = 6.7 \pm 7.8";

const rel135 = "\vmu_{d} = 1.0";
const rel136 = "D \cdot t = 1560x152, 1524x139 mm";
const rel137 = "M/VD = 2.5";
const rel138 = "L = 3581 mm";
const rel139 = "\vrho_{l}(%) = 1.4, 2.3";
const rel140 = "\vrho_{t}(%) = 0.35";
const rel141 = "f_{c}' = 35, 40 MPa";
const rel142 = "f_{y} = 635 MPa";
const rel143 = "P/(f_{c}' A_{g}) = 0.05, 0.15";

const rel144 = "\vmu_{d} = 2.0 \pm 3.0";
const rel145 = "\vmu_{d} = 6.0";
const rel146 = "\vmu_{d} = 3.5";














const LSExperimental = () => {
  //javascript
  const title = 'As Built Piers';
  const metatitle = 'As Built Piers';
  const description = 'Meta Description for As Built Piers';
  const metadescription = description;


  const scrollToRef = (ref) => window.scrollTo({
    left:0,
    top:ref.current.offsetTop,
    behavior: 'smooth'
  });
  
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);
  const Ref16 = useRef(null);
  const Ref17 = useRef(null);
  const Ref18 = useRef(null);
  const Ref19 = useRef(null);
  const Ref20 = useRef(null);
  const Ref21 = useRef(null);
  const Ref22 = useRef(null);
  const Ref23 = useRef(null);
  const Ref24 = useRef(null);
  const Ref25 = useRef(null);
  const Ref26 = useRef(null);
  const Ref27 = useRef(null);
  const Ref28 = useRef(null);
  
  const executeScroll = (e, ref) => {
    e.preventDefault();
    scrollToRef(ref);
  }
  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
        <li>
            <a className="more" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more actclass" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Hollow Circular Piers</h1>
          <div className="content-table">
            <h2>Table 1: Hollow Circular Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> Hoshikuma, J. I. And Priestley, M.J.N (2000)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel1}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel2}</MathJax.Node><br /><MathJax.Node inline>{rel3}</MathJax.Node><br /><MathJax.Node inline>{rel4}</MathJax.Node><br /><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel10}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of concrete cover, flexural cracks 1-2 mm wide</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel11}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended crushing of inside face concrete, subsequent bucking of long. bars</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button> Lee, J.H., Choi, J.H., Hwang, D.K., Kwahk, I.J. (2015)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel12}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Effective yielding</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel13}</MathJax.Node><br /><MathJax.Node inline>{rel14}</MathJax.Node><br /><MathJax.Node inline>{rel15}</MathJax.Node><br /><MathJax.Node inline>{rel16}</MathJax.Node><br /><MathJax.Node inline>{rel17}</MathJax.Node><br /><MathJax.Node inline>{rel18}</MathJax.Node><br /><MathJax.Node inline>{rel19}</MathJax.Node><br /><MathJax.Node inline>{rel20}</MathJax.Node><br /><MathJax.Node inline>{rel21}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel22}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling/fracture of longitudinal reinforcement or crushing of core concrete</td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button> Li, Z.X., Du, C.Y., Liang, X., Zhao, B. (2020)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel23}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel24}</MathJax.Node><br /><MathJax.Node inline>{rel25}</MathJax.Node><br /><MathJax.Node inline>{rel26}</MathJax.Node><br /><MathJax.Node inline>{rel27}</MathJax.Node><br /><MathJax.Node inline>{rel28}</MathJax.Node><br /><MathJax.Node inline>{rel29}</MathJax.Node><br /><MathJax.Node inline>{rel30}</MathJax.Node><br /><MathJax.Node inline>{rel31}</MathJax.Node><br /><MathJax.Node inline>{rel32}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel33}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Spalling of concrete cover</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel34}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of core concrete, buckling of longitudinal bars</td>
                </tr>
                <tr className="even">
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref4)}>[4]</button> Ranzo, G. and Priestley, M.J.N. (2000)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel35}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel36}</MathJax.Node><br /><MathJax.Node inline>{rel37}</MathJax.Node><br /><MathJax.Node inline>{rel38}</MathJax.Node><br /><MathJax.Node inline>{rel39}</MathJax.Node><br /><MathJax.Node inline>{rel40}</MathJax.Node><br /><MathJax.Node inline>{rel41}</MathJax.Node><br /><MathJax.Node inline>{rel42}</MathJax.Node><br /><MathJax.Node inline>{rel43}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel44}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete spalling in the inside face of column</td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel45}</MathJax.Node> (flex. failure)<br /><MathJax.Node inline>{rel46}</MathJax.Node> (shear failure)</div>
                  </MathJax.Context></td>
                  <td>Implosion of concrete in the inside face of column, buckling of longitudinal bars, transverse steel fracture</td>
                </tr>
                
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>Hoshikuma, J. I. And Priestley, M.J.N (2000). FLEXURAL BEHAVIOR OF CIRCULAR HOLLOW COLUMNS WITH A SINGE LAYER OF REINFORCEMENT UNDER SEISMIC LOADING, Report No. SSRP-2000/13, Department of Structural Engineering, University of California, San Diego.</li>
              <li ref={Ref2}>Lee, J.H., Choi, J.H., Hwang, D.K., Kwahk, I.J. (2015). Seismic Performance of Circular Hollow RC Bridge Columns, KSCE Journal of Civil Engineering, Vol. 19, no. 5, pp 1456-1467, <a href="https://doi.org/10.1007/s12205-014-1173-z">https://doi.org/10.1007/s12205-014-1173-z.</a> </li>
              <li ref={Ref3}>Li, Z.X., Du, C.Y., Liang, X., Zhao, B. (2020). Flexural Behavior of Circular Hollow RC Piers with Reduced Amounts of Inner Hoops, International Journal of Concrete Structures and Materials, Vol. 14, no. 9, <a href="https://doi.org/10.1186/s40069-019-0383-7">https://doi.org/10.1186/s40069-019-0383-7</a></li>
              <li ref={Ref4}>Ranzo, G. and Priestley, M.J.N. (2000). Seismic Performance of Large RC Circular Hollow Columns, 12th World Conference on Earthquake Engineering, New Zealand 2000, Paper No. 250.</li>
            </ol>
          </div>
        </section>

        <section className="exp-section">
          <div className="content-table">
            <h2>Table 2: Hollow Circular Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility (μ<sub>d</sub>)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref11)}>[1]</button> Hoshikuma, J. I. And Priestley, M.J.N (2000)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel101}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel102}</MathJax.Node><br /><MathJax.Node inline>{rel103}</MathJax.Node><br /><MathJax.Node inline>{rel104}</MathJax.Node><br /><MathJax.Node inline>{rel105}</MathJax.Node><br /><MathJax.Node inline>{rel106}</MathJax.Node><br /><MathJax.Node inline>{rel107}</MathJax.Node><br /><MathJax.Node inline>{rel108}</MathJax.Node><br /><MathJax.Node inline>{rel109}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel110}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of concrete cover, flexural cracks 1-2 mm wide</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel111}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended crushing of inside face concrete, subsequent bucking of long. bars</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref12)}>[2]</button> Lee, J.H., Choi, J.H., Hwang, D.K., Kwahk, I.J. (2015)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel112}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Effective yielding</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel113}</MathJax.Node><br /><MathJax.Node inline>{rel114}</MathJax.Node><br /><MathJax.Node inline>{rel115}</MathJax.Node><br /><MathJax.Node inline>{rel116}</MathJax.Node><br /><MathJax.Node inline>{rel117}</MathJax.Node><br /><MathJax.Node inline>{rel118}</MathJax.Node><br /><MathJax.Node inline>{rel119}</MathJax.Node><br /><MathJax.Node inline>{rel120}</MathJax.Node><br /><MathJax.Node inline>{rel121}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel122}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling/fracture of longitudinal reinforcement or crushing of core concrete</td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref13)}>[3]</button> Li, Z.X., Du, C.Y., Liang, X., Zhao, B. (2020)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel123}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel124}</MathJax.Node><br /><MathJax.Node inline>{rel125}</MathJax.Node><br /><MathJax.Node inline>{rel126}</MathJax.Node><br /><MathJax.Node inline>{rel127}</MathJax.Node><br /><MathJax.Node inline>{rel128}</MathJax.Node><br /><MathJax.Node inline>{rel129}</MathJax.Node><br /><MathJax.Node inline>{rel130}</MathJax.Node><br /><MathJax.Node inline>{rel131}</MathJax.Node><br /><MathJax.Node inline>{rel132}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel133}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Spalling of concrete cover</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel134}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of core concrete, buckling of longitudinal bars</td>
                </tr>
                <tr className="even">
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref14)}>[4]</button> Ranzo, G. and Priestley, M.J.N. (2000)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel135}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel136}</MathJax.Node><br /><MathJax.Node inline>{rel137}</MathJax.Node><br /><MathJax.Node inline>{rel138}</MathJax.Node><br /><MathJax.Node inline>{rel139}</MathJax.Node><br /><MathJax.Node inline>{rel140}</MathJax.Node><br /><MathJax.Node inline>{rel141}</MathJax.Node><br /><MathJax.Node inline>{rel142}</MathJax.Node><br /><MathJax.Node inline>{rel143}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel144}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete spalling in the inside face of column</td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel145}</MathJax.Node> (flex. failure)<br /><MathJax.Node inline>{rel146}</MathJax.Node> (shear failure)</div>
                  </MathJax.Context></td>
                  <td>Implosion of concrete in the inside face of column, buckling of longitudinal bars, transverse steel fracture</td>
                </tr>
                
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref11}>Hoshikuma, J. I. And Priestley, M.J.N (2000). FLEXURAL BEHAVIOR OF CIRCULAR HOLLOW COLUMNS WITH A SINGE LAYER OF REINFORCEMENT UNDER SEISMIC LOADING, Report No. SSRP-2000/13, Department of Structural Engineering, University of California, San Diego.</li>
              <li ref={Ref12}>Lee, J.H., Choi, J.H., Hwang, D.K., Kwahk, I.J. (2015). Seismic Performance of Circular Hollow RC Bridge Columns, KSCE Journal of Civil Engineering, Vol. 19, no. 5, pp 1456-1467, <a href="https://doi.org/10.1007/s12205-014-1173-z">https://doi.org/10.1007/s12205-014-1173-z.</a> </li>
              <li ref={Ref13}>Li, Z.X., Du, C.Y., Liang, X., Zhao, B. (2020). Flexural Behavior of Circular Hollow RC Piers with Reduced Amounts of Inner Hoops, International Journal of Concrete Structures and Materials, Vol. 14, no. 9, <a href="https://doi.org/10.1186/s40069-019-0383-7">https://doi.org/10.1186/s40069-019-0383-7</a></li>
              <li ref={Ref14}>Ranzo, G. and Priestley, M.J.N. (2000). Seismic Performance of Large RC Circular Hollow Columns, 12th World Conference on Earthquake Engineering, New Zealand 2000, Paper No. 250.</li>
            </ol>
          </div>
        </section>
        
      </div>
      
    </Layout>
  )
}

export default LSExperimental
